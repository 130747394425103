<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="2" lg="1" class="mx-0 px-0">
                        <AlternativeAutocompleteOne
                            :label="'Año'"
                            :items="serverItems.years"
                            :valueInput="selectedYear"
                            :loading="loadingYears"
                            @valueChange="(v) => {selectedYear = v; index()}"
                        ></AlternativeAutocompleteOne>
					</v-col>
					<v-spacer></v-spacer>
					<v-col v-permission="'holidays.create'" cols="12" md="3" lg="3" xl="2" class="mx-0 px-0">
                        <primaryButton :props="createBtnData"></primaryButton>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<datatable :props="tableProps" @method_handler="method_handler"></datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <!-- DELETE -->
        <modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>

        <!-- FORM -->
        <modal :props="modalFormProps" @modalResponse="method_handler">
            <!-- Body -->
           
            <template slot="cardBody">
               
                <div>
                    <v-form ref="form" @submit.prevent="save" class="mt-5">
                        <holi-form ref="HoliForm" :values="form" @save="save" :serverErrors="serverErrors" ></holi-form>
                    </v-form>
                    

                    <v-row justify="start" class="mt-4" v-if="!editMode">
                        <v-col class="my-0 py-0">
                            <checkboxForm
                            label="Mantenerme en esta página después de guardar"
                            :valueInput="autoCloseModal"
                            :rule="[]"
                            @valueChange="(v) => {autoCloseModal = v; }"
                            ></checkboxForm>
                        </v-col>

                    </v-row>

                </div>
            
			</template>
			<!-- Buttons -->
            
			<template slot="cardActions" v-if="!loadingForm">
                    <v-row justify="end" >
                        <v-col cols="12" sm="3">
                            <secondaryButton
                                :props="cancelFormModal"
                            ></secondaryButton>
                        </v-col>
                        
                        <v-col cols="12" sm="3"  class="mr-sm-4 mb-4 mb-sm-0" :key="updateButtons">
                            <primaryButton
                            v-if="!editMode"
                            :props="acceptFormModal"
                            ></primaryButton>
                            <primaryButton
                            v-if="editMode"
                            :props="updateFormModal"
                            ></primaryButton>
                        </v-col>
                    </v-row>
			</template> 
		</modal>
		
	</div>
</template>
<script>
import Utils from '../../../helpers/Utils';
import HoliForm from "./Form.vue";
export default {
    components: {
        'holi-form': HoliForm,
    },
    data(){
		return {
            updateButtons:0,
            editMode: false,
            loadingForm: false,
            autoCloseModal: false,
            loadingYears: false,
            selectedYear:"2023",
            serverItems:{
                years: []
            },
            serverErrors: {},
            form:{
                date: "",
                name: "",
            },
			actions: [
				
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'editFormModal',
                    permissions: 'holidays.update'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'holidays.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Fecha',
						align: 'left',
						value: 'date',
						width: '8%',
						class: 'table-b-border'
					},
                    {
						text: 'Festividad',
						align: 'left',
						value: 'name',
						class: 'table-b-border'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border'
					}
				],
				items: [],
				search: '',
				loading: false
			},
			
			
            createBtnData: {
                text: "Nuevo festivo",
                icon: "mdi-plus",
				to: "",
				block: true,
                click: ()=>{this.openFormModal()}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},

			modalFormProps: {
				visible: false,
				width: '700',
				title: 'Nuevo Día Festivo',
				text: 'Los días que crees como festivos serán considerados como no laborales en el calendario de asistencias para todos los empleados',
				loading: false,
				bottomLineColor: 'primary',
				
			},
			
			cancelModal: {
                text: "No, regresar",
                icon: "",
				to: "",
				block: false,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: false,
				color: 'error',
				textColor:'white',
				loading:false,
                click: ()=>{this.delete()}
            },

            cancelFormModal: {
                text: "Cancelar",
                classes: "",
                icon: "mdi-close",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.closeFormModal()}
            },
            acceptFormModal: {
                text: "Guardar",
                icon: "mdi-content-save-outline",
				to: "",
				block: true,
				color: 'primary',
				textColor:'black',
				loading:false,
                click: ()=>{this.save()}
            },
            updateFormModal: {
                text: "Guardar",
                icon: "mdi-content-save-outline",
				to: "",
				block: true,
				color: 'primary',
				textColor:'black',
				loading:false,
                click: ()=>{this.update()}
            },
		}
	},

	mounted(){
		this.getYears();
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
        validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate()
		},
        trySearch(val){
            this.tableProps.search = val;
        },
        getYears()
        {
            // 
            if(this.selectedYear == "" || this.selectedYear == undefined)
                return;
			this.tableProps.loading = true;
			
			//call api
			this.$api.holidays.getAvailableYears()
				.then((resp) =>{
					//console.log(resp, "desde areas");
					this.serverItems.years = resp.data.map((item) => {
                        return {text: item.year, value: item.year}
                    });
                    this.selectedYear = this.serverItems.years[0].value??"";
                    this.index();
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;

        },
		index() {
            if(this.selectedYear == "" || this.selectedYear == undefined)
                return;
			this.tableProps.loading = true;
			
			//call api
			this.$api.holidays.indexByYear(this.selectedYear)
				.then((resp) =>{
					//console.log(resp, "desde areas");
					this.tableProps.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
						

						x['parameters'] = { id: x.id, name: x.name};
                        x.date = Utils.globalDateFormat(x.date);
						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.holidays.delete(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se eliminó correctamente`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				

				this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},



        openFormModal()
        {
            this.modalFormProps.title = "Nuevo Día Festivo";
            this.modalFormProps.text = "Los días que crees como festivos serán considerados como no laborales en el calendario de asistencias para todos los empleados";
            this.autoCloseModal = false;
            this.editMode = false;
            this.resetForm();
            this.modalFormProps.visible = true;
            
        },

        editFormModal(parameters)
        {
            this.resetForm();
            this.modalFormProps.title = "Editar día festivo";
            this.modalFormProps.text = "";
            this.editMode = true;
            this.modalFormProps.loading = true;
            this.selectedID = parameters.id;
            this.loadingForm = true;
            this.modalFormProps.visible = true;
            

            this.$api.holidays.edit(this.selectedID)
				.then((resp) =>{
					//console.log(resp, "desde areas");
					this.form.date = resp.data.date;
					this.form.name = resp.data.name;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
					this.modalFormProps.loading = false;
                    this.loadingForm = false;
				})
			;

        },

        closeFormModal()
        {
            this.modalFormProps.visible = false;
        },

        save()
        {
            this.acceptFormModal.loading = true;
            this.$store.state.loading = true;
            console.log(this.form);
            
            if(this.validate()){
                
                let form = JSON.parse(JSON.stringify(this.form))
                
                this.$api.holidays.store(form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        if(this.serverItems.years.length == 0)
                            this.getYears();
                        else
                            this.index();
                        this.resetForm();
                        if(!this.autoCloseModal)
                            this.closeFormModal();
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.acceptFormModal.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                this.acceptFormModal.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        
        },

        update()
        {
            this.acceptFormModal.loading = true;
            this.$store.state.loading = true;
            console.log(this.form);
            
            if(this.validate()){
                
                let form = JSON.parse(JSON.stringify(this.form))
                
                this.$api.holidays.update(this.selectedID, form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
                        
                        this.index();
                        
                        this.closeFormModal();
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.acceptFormModal.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                this.acceptFormModal.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        
        },

        resetForm()
        {
            
            this.form.date = "";
            this.form.name = "";
            try {
                this.$refs.form.reset();
            } catch (error) {
                console.log(error);
            }
        }

    }
}
</script>

<style lang="scss">
    
    .testtest .v-skeleton-loader__text{
        height: 35px !important;
    }
</style>