<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-5">
                        <div class="font-weight-bold body-1">
                            Editar Candidato
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <v-form ref="form" @submit.prevent="save">
                            <candidate-form ref="CandidateForm" :values="form" @save="save" :serverErrors="serverErrors"></candidate-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

            </div>

            <v-row justify="center">
				<v-col cols="12" md="5" class="d-flex justify-end">
                    <secondary-button :props="cancelBtnData" class="mr-2"></secondary-button>
					<primary-button :props="saveBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Object2Form from '../../../../helpers/Object2Form';
import Utils from '../../../../helpers/Utils';
import CandidateForm from "./Form.vue";
export default {
    components: {
        'candidate-form': CandidateForm,
    },
	data(){
		return {
            loading: false,
            serverErrors: {},
            jobId: this.$route.params.jobId,
            candidateId: this.$route.params.candidateId,
            form: {
                name: '',
                phone: '',
                means_of: '',
                interviewer_id: '',
                interview_date: '',
                resume: '', //????
                resume_file: [],
                resume_url: ''
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Candidates",	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        this.index();
    },
	methods: {
        index()
        {
            //this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.jobs.getCandidate(this.jobId, this.candidateId)
			.then((response) => {
                this.form = response.data;
                this.form = {
                    ...this.form, 
                    interviewer_id: (response.data.interviewer.id)??'',
                    resume_file: []
                }
                delete this.form.status;
                delete this.form.interviewer;
               
				this.$refs.form.reset();
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
        },
		validate () {
			return this.$refs.form.validate()
            //return true;
		},
        save(){
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
           
            // this.$store.state.overlay = true
            console.log(this.form);
            if(this.validate()){
                
                let formData = this.prepareResponse(this.form);
                console.log(formData);
                const request = Object2Form.objectToFormData(formData,'',[]);
                
                this.$api.jobs.updateCandidate(this.jobId, this.candidateId, request)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
                        this.$router.push({name: 'Candidates'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                this.saveBtnData.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },

        /**
         * Process data
         */
        prepareResponse(data)
        {
            let newData = JSON.parse(JSON.stringify(data));

            //newData.resume_file = data.resume_file;

            //photo_file
            delete newData.resume_file;
            if(data.resume_file instanceof File)
                newData.resume_file = data.resume_file;
            else if(data.resume_url != '')
                newData.resume_url = 'path';

            return newData;
        }
    },
}
</script>

<style>

</style>