<template>
    <div>
        <v-row justify="start" >
            <v-col class="text-left col-md-6 mb-0 pb-0">
                <div class="font-weight-bold body-2">
                    Actualización de datos
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm
                    name="p1"
                    label="Nombre completo"
                    :valueInput="values.name"
                    :rule="rules.required"
                    @valueChange="(v) => {values.name = v.trim(); delete serverErrors.name}"
                    :errorMessages="serverErrors.name"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm
                    name="p1"
                    label="Correo Institucional"
                    :valueInput="values.business_email"
                    :rule="rules.business_email"
                    @valueChange="(v) => {values.business_email = v.trim(); serverErrors.business_email; delete serverErrors.business_email}"
                    :errorMessages="serverErrors.business_email"
                ></textFieldForm>

               
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm
                    name="p1"
                    label="Correo personal"
                    :valueInput="values.email"
                    :rule="rules.email"
                    @valueChange="(v) => {values.email = v.trim(); serverErrors.email; delete serverErrors.email}"
                    :errorMessages="serverErrors.email"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <textFieldForm
                name="p1"
                label="Profesión"
                :valueInput="values.profession"
                :rule="rules.required"
                @valueChange="(v) => {values.profession = v.trim(); delete serverErrors.profession}"
                :errorMessages="serverErrors.profession"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <autocompleteOneForm
                    name="p1"
                    label="Lugar de nacimiento"
                    :valueInput="values.birthplace"
                    :rule="rules.birthplace"
                    :items="serverItems.nationalities"
                    @valueChange="(v) => {values.birthplace = v; delete serverErrors.birthplace}"
                    :errorMessages="serverErrors.birthplace"
                ></autocompleteOneForm>
            </v-col>

            <v-col>
                <datePickerForm
                    name="p1"
                    label="Fecha de nacimiento"
                    :valueInput="values.birthdate"
                    :rule="rules.birthdate"
                    @valueChange="(v) => {values.birthdate = v; delete serverErrors.birthdate}"
                    :errorMessages="serverErrors.birthdate"
                ></datePickerForm>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col cols="6">
                <autocompleteOneForm
                    name="p1"
                    label="Género"
                    :valueInput="values.gender"
                    :rule="rules.required"
                    :items="serverItems.genders"
                    @valueChange="(v) => {values.gender = v; delete serverErrors.gender}"
                    :errorMessages="serverErrors.gender"
                ></autocompleteOneForm>
            </v-col>

            <v-col>
                <autocompleteOneForm
                    name="p1"
                    label="Estado civil"
                    :valueInput="values.marital_status"
                    :rule="rules.required"
                    :items="serverItems.marital_statuses"
                    @valueChange="(v) => {values.marital_status = v; delete serverErrors.marital_status}"
                    :errorMessages="serverErrors.marital_status"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
       
        <v-row>
            <v-col cols="6">
                <textFieldForm
                    name="p1"
                    label="C.U.R.P."
                    :valueInput="values.curp"
                    :rule="rules.required"
                    @valueChange="(v) => {values.curp = v.trim(); delete serverErrors.curp}"
                    :errorMessages="serverErrors.curp"
                ></textFieldForm>
            </v-col>

            <v-col>
                <textFieldForm
                    name="p1"
                    label="R.F.C."
                    :valueInput="values.rfc"
                    :rule="rules.required"
                    @valueChange="(v) => {values.rfc = v.trim(); delete serverErrors.rfc}"
                    :errorMessages="serverErrors.rfc"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <textFieldForm
                    name="p1"
                    label="INFONAVIT"
                    :valueInput="values.infonavit_number"
                    :rule="[...rules.required, ...rules.infonavit_number]"
                    @valueChange="(v) => {values.infonavit_number = v.trim(); delete serverErrors.infonavit_number}"
                    :errorMessages="serverErrors.infonavit_number"
                ></textFieldForm>
            </v-col>

            <v-col>
                <textFieldForm
                    name="p1"
                    label="FONACOT"
                    :valueInput="values.fonacot_number"
                    :rule="[...rules.required, ...rules.fonacot_number]"
                    @valueChange="(v) => {values.fonacot_number = v.trim(); delete serverErrors.fonacot_number}"
                    :errorMessages="serverErrors.fonacot_number"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <textFieldForm
                    name="p1"
                    label="N.S.S."
                    :valueInput="values.nss"
                    :rule="rules.nss"
                    @valueChange="(v) => {values.nss = v.trim(); delete serverErrors.nss}"
                    :errorMessages="serverErrors.nss"
                ></textFieldForm>
            </v-col>

            <v-col>
                <textFieldForm
                    name="p1"
                    label="Teléfono"
                    :valueInput="values.phone"
                    :rule="rules.phone"
                    @valueChange="(v) => {values.phone = v.trim(); delete serverErrors.phone}"
                    :errorMessages="serverErrors.phone"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <autocompleteOneForm
                    name="p1"
                    label="Tipo de sangre"
                    :valueInput="values.blood_type"
                    :rule="[]"
                    :items="serverItems.blood_types"
                    @valueChange="(v) => {values.blood_type = v; delete serverErrors.blood_type}"
                    :errorMessages="serverErrors.blood_type"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <autocompleteMultipleForm
                    name="p1"
                    label="Enfermedades Crónicas"
                    :valueInput="values.diseases"
                    :rule="[]"
                    :items="serverItems.diseases"
                    @valueChange="(v) => {values.diseases = v; delete serverErrors.diseases}"
                    :errorMessages="serverErrors.diseases"
                ></autocompleteMultipleForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <textFieldForm
                    name="p1"
                    label="Calle"
                    :valueInput="values.street"
                    :rule="rules.street"
                    @valueChange="(v) => {values.street = v.trim(); delete serverErrors.street}"
                    :errorMessages="serverErrors.street"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <textFieldForm
                    name="p1"
                    label="No. Exterior"
                    :valueInput="values.ext_number"
                    :rule="rules.ext_number"
                    @valueChange="(v) => {values.ext_number = v.trim(); delete serverErrors.ext_number}"
                    :errorMessages="serverErrors.ext_number"
                ></textFieldForm>
            </v-col>

            <v-col>
                <textFieldForm
                    name="p1"
                    label="No. Interior"
                    :valueInput="values.int_number"
                    :rule="[]"
                    @valueChange="(v) => {values.int_number = v.trim(); delete serverErrors.int_number}"
                    :errorMessages="serverErrors.int_number"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <location-information
                name="p1"
                :valuesInput="values"
                :rules="rules"
                :serverErrors="serverErrors"
                countryLabel="País de residencia"
                :hideCountry="true"
                ></location-information>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <textFieldForm
                    name="p1"
                    label="Contacto de emergencia"
                    :valueInput="values.emergency_contact"
                    :rule="rules.required"
                    @valueChange="(v) => {values.emergency_contact = v.trim(); delete serverErrors.emergency_contact}"
                    :errorMessages="serverErrors.emergency_contact"
                ></textFieldForm>
            </v-col>

            <v-col>
                <textFieldForm
                    name="p1"
                    label="Teléfono de emergencia"
                    :valueInput="values.emergency_number"
                    :rule="rules.emergency_number"
                    @valueChange="(v) => {values.emergency_number = v.trim(); delete serverErrors.emergency_number}"
                    :errorMessages="serverErrors.emergency_number"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <p class="text-caption font-weight-black black--text mb-0">
                    Indica si tienes hijos y da clic en agregar tantas veces como hijos tengas para registrar los datos de cada uno
                </p>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <switchForm
                    title="Tiene hijos"
                    leftLabel=""
                    rightLabel="Si"
                    :valueInput="values.has_childs"
                    :rule="[]"
                    @valueChange="(v) => {values.has_childs = v}"
                ></switchForm>
            </v-col>
            <v-col v-if="values.has_childs">
                <secondary-button :props="addBtnData" class="mr-2"></secondary-button>
            </v-col>
        </v-row>

        <v-expand-transition v-for="(itemOpt, indexChild) in values.children" :key="indexChild" appear >
            <v-row align="center">
                <v-col cols="10" class="p-0">
                    <v-row>
                        <v-col cols="6" class="pr-0">
                            <autocompleteOneForm
                                name="p1"
                                label="Género"
                                :valueInput="itemOpt.gender"
                                :rule="rules.required"
                                :items="serverItems.genders"
                                @valueChange="(v) => {itemOpt.gender = v; delete serverErrors[`children.${indexChild}.option_list.${indexChild}.option`]}"
                                :errorMessages="serverErrors[`children.${indexChild}.option_list.${indexChild}.option`]"
                            ></autocompleteOneForm>
                        </v-col>
                        <v-col cols="6" class="pr-0">
                            <datePickerForm
                                name="p1"
                                label="Fecha de nacimiento"
                                :valueInput="itemOpt.birthdate"
                                :rule="rules.required"
                                @valueChange="(v) => {itemOpt.birthdate = v; delete serverErrors[`children.${indexChild}.option_list.${indexChild}.option`]}"
                                :errorMessages="serverErrors[`children.${indexChild}.option_list.${indexChild}.option`]"
                            ></datePickerForm>
                        </v-col>
                    </v-row>
                    
                
                </v-col>

                <v-col cols="2"  align-self="center">
                    <v-row justify-sm="end" class="mr-0">
                        <iconButton
                            :props="{
                            color:'warning',
                            icon:'mdi-close',
                            height:'39',
                            click: () => {removeChild(indexChild)}
                        }"
                        ></iconButton>
                    </v-row>
                </v-col>
            </v-row>
        </v-expand-transition>



    </div>
</template>

<script>
import LocationInformation from '../../../components/LocationInformation.vue';
/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
    components: {
      'location-information': LocationInformation,
    },
    data(){
        return {
           
            rules: {
				required: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                
                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]*?$"))
                            return 'Solo números válidos';
                        if(!v)
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                age: [
                    v => {
                        // if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                        //     return 'Solo números válidos';
                        if(!v)
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                email: [
                    //v => !!v || 'El campo es requerido'
                    v => {
                        if(!v)
                        return 'El campo es requerido';
                        if(v.length > 0) {
                            try {
                                if(v.trim() == "")
                                    return "Campo requerido";
                            } catch (error) {
                                console.log(error);
                            }
                            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            return pattern.test(v) || 'Correo no válido';
                        }
                        return true
                    }
                ],
                business_email:[
                    v => {
                        if(v.length > 0) {
                            try {
                                if(v.trim() == "")
                                    return "Campo requerido";
                            } catch (error) {
                                console.log(error);
                            }
                            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            return pattern.test(v) || 'Correo no válido';
                        }
                        return true
                    }
                ],

                phone: [
                    v => {
                        if(!v)
                            return 'El campo es requerido';
                        if(v!= null && !v.match("^[0-9]*$"))
                            return 'Solo números';
                        if(v.length != 10){
                            return 'Máximo 10 caracteres';
                        }
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                payee_family_phone:[
                    v => {
                    
                        if(v!= null && !v.match("^[0-9]*$"))
                            return 'Solo números';
                        if(v.length > 10){
                            return 'Máximo 10 caracteres';
                        }
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],

                emergency_number: [
                    v => {
                        if(!v)
                            return 'El campo es requerido';
                        if(v!= null && !v.match("^[0-9]*$"))
                            return 'Solo números';
                        if(v.length < 10){
                            return 'Máximo 10 caracteres';
                        }
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                fonacot_number:[
                    v => {
                    
                        // if(v!= null && !v.match("^[0-9]*$"))
                        // return 'Solo números';
                        if(v.length > 5){
                            try {
                                if(v.trim() == "")
                                    return "Campo requerido";
                            } catch (error) {
                                console.log(error);
                            }
                            return 'Máximo 5 caracteres';
                        }
                        return true;
                    }
                ],
                infonavit_number:[
                    v => {
                        
                        // if(v!= null && !v.match("^[0-9]*$"))
                        // return 'Solo números';
                        if(v.length > 10){
                            try {
                                if(v.trim() == "")
                                    return "Campo requerido";
                            } catch (error) {
                                console.log(error);
                            }
                            return 'Máximo 10 caracteres';
                        }
                        return true;
                    }
                ],
             
            },
            serverItems: {
                nationalities:[],
                genders: [
                    //Masculino, Femenino, No Binario, Otro
                    {text: "Masculino", value: "MASCULINO"},
                    {text: "Femenino", value: "FEMENINO"},
                    {text: "No Binario", value: "NO BINARIO"},
                    {text: "Otro", value: "OTRO"},
                ],
                marital_statuses:[
                    //Soltero, Casado, Unión Libre
                    {text: "Soltero", value: "SOLTERO"},
                    {text: "Casado", value: "CASADO"},
                    {text: "Unión Libre", value: "UNIÓN LIBRE"},
                ],
                blood_types:[
                    //A+, A-, B+, B-, AB+, AB-, O+, O-
                    {text: "A+", value: "A+"},
                    {text: "A-", value: "A-"},
                    {text: "B+", value: "B+"},
                    {text: "B-", value: "B-"},
                    {text: "AB+", value: "AB+"},
                    {text: "AB-", value: "AB-"},
                    {text: "O+", value: "O+"},
                    {text: "O-", value: "O-"},
                ],
                diseases:[
                    // {text: "Asma", value: "ASMA"},
                    // {text: "Diabetes", value: "DIABETES"},
                    // {text: "Otro", value: "OTRO"},
                    {text: "Diabetes mellitus", value: 'DIABETES MELLITUS'},
                    {text: "hipertensión arterial sistémica", value: 'HIPERTENSIÓN ARTERIAL SISTÉMICA'},
                    {text: "Enfermedad pulmonar", value: 'ENFERMEDAD PULMONAR'},
                    {text: "Asma", value: 'ASMA'},
                    {text: "Enfermedad cardiovascular", value: 'ENFERMEDAD CARDIOVASCULAR'},
                    {text: "Enfermedad cerebrovascular" , value: 'ENFERMEDAD CEREBROVASCULAR'},
                    {text: "Enfermedad renal Crónica", value: 'ENFERMEDAD RENAL CRÓNICA'},
                    {text: "Enfermedad reumatológica", value: 'ENFERMEDAD REUMATOLÓGICA'},
                    {text: "VIH", value: 'VIH'},
                    {text: "Otros", value: 'OTROS'},
                ],
            },

            addBtnData: {
                text: "Agregar Hijo/a/e",
                icon: "",	
                click: this.addChild,
                color: 'primary',
                loading: false,
                block: true,
            },

          
        }
    },
    mounted(){
        this.getCountries();
    },
    methods: {
        addChild()
        {
            if(!this.values.has_childs)
                return;

            this.values.children.push(
                {birthdate: "", gender: ""}
            )
        },

        removeChild(index)
        {
            this.values.children.splice(index,1);
        },
        
        /**
            Get countries from api
        */
        getCountries()
        {
            this.$locationsApi.locations.getCountries()
            .then((resp) => {
                this.serverItems.nationalities = resp.data;
                //console.log(resp.data);
            
                
            });
        },
    
    }
}
</script>

<style lang="scss">
</style>