<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" class="mx-0 px-0">
						<!-- <v-text-field v-model="tableProps.search" label="Buscar un banco" clearable prepend-inner-icon="mdi-magnify" hide-details></v-text-field> -->
                        <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}" 
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            data-cy="search-input"
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3 mr-1"
                        ></alternativeTextField2>
					</v-col>
                    <v-col cols="12" md="3" class="mx-0 px-0">
						<v-row class="" justify="start" no-gutters>
						
							<v-col class="">
                                <AlternativeAutocompleteOne
                                    :label="'Empresa'"
                                    :items="serverItems.companies"
                                    :valueInput="selectedCompany"
                                    :loading="loadingCompanies"
                                    @valueChange="(v) => {selectedCompany = v; selectedOffice = ''; index()}"
                                ></AlternativeAutocompleteOne>
                            </v-col>
						</v-row>
					</v-col>
					<v-spacer></v-spacer>
					<v-col v-permission="'branch_offices.create'" cols="12" md="3" lg="2" class="mx-0 px-0">
						<primaryButton :props="createBtnData"></primaryButton>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<datatable :props="tableProps" @method_handler="method_handler"></datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
		
	</div>
</template>
<script>
import PageStateManager from '../../../helpers/PageStateManager'

export default {
    data(){
		return {
            selectedCompany: PageStateManager.getState(this.$router.history.current.path, 'selectedCompany')??'',
            loadingCompanies: false,
			countries: [],
			states: [],
			actions: [
				{
					name: 'Detalle',
					icon: {
						color: 'secondary',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'branch_offices.show'
				},
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'branch_offices.update'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'branch_offices.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Código',
						align: 'left',
						value: 'id',
						class: 'table-b-border'
					},
                    {
						text: 'Nombre',
						align: 'left',
						value: 'name',
						class: 'table-b-border'
					},
                    {
						text: 'Empresa',
						align: 'left',
						value: 'company.name',
						class: 'table-b-border'
					},
                    {
						text: 'País',
						align: 'left',
						value: 'country',
						class: 'table-b-border'
					},
                    {
						text: 'Estado',
						align: 'left',
						value: 'state',
						class: 'table-b-border'
					},
                    {
						text: 'Estatus',
						align: 'left',
						value: 'status',
						class: 'table-b-border'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'table-b-border'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            serverItems: {
                companies: [],
            },
			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			cancelModal: {
                text: "No, regresar",
                icon: "",
				to: "",
				block: false,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: false,
				color: 'error',
				textColor:'white',
				loading:false,
                click: ()=>{this.delete()}
            },
		
			createBtnData: {
				text: "Nueva Locación",
				icon: "mdi-plus",
				to: "LocationsCreate",
				block: true,
				click: ()=>{}
			}
		}
	},

	mounted(){
		this.getCompanies()
	},
    watch: {
        /**
         * Reset values on "type" change
         */
        [`selectedCompany`]: function () {
            console.log(this.selectedCompany)
            PageStateManager.setState(this.$router.history.current.path, 'selectedCompany', this.selectedCompany)
        },
       
        
        // prevHeight: function(v){
        //     if(v != 0)
        //         this.prevHeight = 0;
        // }
    },

  methods: {
    method_handler(object){
      this[object.method_name](object.parameters)
    },
    trySearch(val){
        this.tableProps.search = val;
    },
    index() {
		this.tableProps.loading = true;
		//call api
		this.$api.locations.index(this.selectedCompany)
			.then((resp) =>{
				
				//console.log(resp, "desde locations");
				this.tableProps.items = resp.data.map((x) => {
					x['actions'] = JSON.parse(JSON.stringify(this.actions))
					x.actions[0].action = { name: 'LocationsDetails', params: { id: x.id } }
					x.actions[1].action = { name: 'LocationsEdit', params: { id: x.id } }

					
					x.state_code = x.state;
					x.country_code = x.country;
					x.state = '-';
					x.country = '-';
				
					x['parameters'] = { id: x.id, name: x.name};
					x.status = 'Activo';
					// x.status = x.status == true ? 'Activo' : 'Inactivo';
					return x
				});

				//get and set country
				this.getCountries();
				//get and set state
				this.getStates();

			})
			.catch((error) =>{
				console.log(error, "error desde locations");
			})
			.finally(() => {
				this.tableProps.loading = false;
			})
		;
		//console.log(test);
      
      
      
    },

	/**
	 * Get countries and set in the table item 
	 */
	getCountries()
	{
		this.$locationsApi.locations.getCountries()
		.then((resp) => {
			this.countries = resp.data;
		
			this.tableProps.items.map((x) =>{
				let foundCountry = this.countries.filter((it) => it.value == x.country_code)[0];
				if(foundCountry != undefined){
					x.country = foundCountry.text;
				}
			})
			
		});
	},

	/**
	 * Get states and set in the table item 
	*/
	getStates()
	{
		this.$locationsApi.locations.getStates()
		.then((resp) => {
			this.states = resp.data;
			this.tableProps.items.map((x) =>{
				x.state = x.state_code;
				if(x.country_code == 'MX'){
					let foundState = this.states.filter((it) => it.value == x.state_code)[0];
					if(foundState != undefined){
						x.state = foundState.text;
					}
				}
			})
			
		});
	},


	deleteItemModal(parameters)
	{
		
		this.selectedID = parameters.id;
		this.modalProps = {
			...this.modalProps,
			text: `¿Estás seguro de eliminar "${parameters.name}"?`,
			
		}
		this.modalProps.visible = true;	
	},


	delete()
	{
		//console.log(this.selectedID);
		this.acceptModal.loading = true;
		this.$api.locations.delete(this.selectedID)
		// eslint-disable-next-line
		.then((response) => {
			
			this.$store.dispatch('snackbarSuccess', `Se eliminó la locación`);
			this.index()
			// eslint-disable-next-line
		}).catch(error => {
			

			this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
		})
		.finally(() => {
			this.modalProps.visible = false;
			this.acceptModal.loading = false;
		})
	},

    getCompanies()
    {
        this.loadingCompanies = true;
        this.$api.user.getUserBusinessSelect()
            .then((resp) =>{
                
                this.serverItems.companies.push({value:"TODAS", text: "TODAS"});
                resp.data.map((comp) => {
                    this.serverItems.companies.push({text: comp.name, value: comp.id})
                });

                if(this.serverItems.companies.length >= 1){
                    if(this.selectedCompany == "")
                        this.selectedCompany = this.serverItems.companies[0].value;
                    this.index();
                    
                }
                
            })
            .catch((error) =>{
                console.log(error, "error getBusinessSelect");
            })
            .finally(() => {
                this.loadingCompanies = false;
            })
        ;
    },

  }
}
</script>