<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-5">
                        <div class="font-weight-bold body-1">
                            Nuevo Activo
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <v-form ref="form" @submit.prevent="save">
                            <inventory-form ref="InventoryForm" :values="form" @save="save" :serverErrors="serverErrors"></inventory-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

            </div>

            <v-row justify="center">
				<v-col cols="12" md="5" class="d-flex justify-end">
                    <secondary-button :props="cancelBtnData" class="mr-2"></secondary-button>
					<primary-button :props="saveBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import PrimaryButton from '../../components/primaryButton.vue';
import InventoryForm from "./Form.vue";
import Utils from "../../helpers/Utils";
export default {
    components: {
        'inventory-form': InventoryForm,
        PrimaryButton,
    },
	data(){
		return {
            loading: false,
            serverErrors: {},
            form: {
                description: '',
                model: '',
                brand: '',
                serial_number: '',
                color: '',
                comments: '',
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Inventory",	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        
    },
	methods: {
		validate () {
            //return true;
			return this.$refs.form.validate();
		},
        save(){
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
           
          

            if(this.validate()){
                
                let form = JSON.parse(JSON.stringify(this.form))
                
                this.$api.inventory.store(form)
                    .then((response) => {
                        console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'Inventory'});
                    })
                    .catch((error) => {
                        console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                this.saveBtnData.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style>

</style>