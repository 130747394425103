<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" class="mx-0 px-0">
						
					</v-col>
					<v-spacer></v-spacer>
					<v-col v-permission="'vacancies.create'" cols="12" md="3" lg="2" class="mx-0 px-0">
                        <primaryButton :props="createBtnData"></primaryButton>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<datatable :props="tableProps" @method_handler="method_handler">
							<!-- chip status item -->
							<template v-slot:[`item.statusJobs`]="{ item }">
								<chip-item :color="getColorStatusJobs(item.statusJobs)" :text="item.statusJobs"></chip-item>
							</template> 
							<!--  -->
						</datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <!-- <modal :props="modalProps" @modalResponse="method_handler"></modal> -->

		
	</div>
</template>
<script>
import ChipDataTableItem from "../../../components/ChipDataTableItem.vue";
import Utils from '../../../helpers/Utils';
export default {
	components: {
       'chip-item': ChipDataTableItem
    },
    data(){
		return {
			actions: [
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/vacantes/1/edicion',
                    permissions: 'vacancies.update'
				},
                {
					name: 'Copiar',
					icon: {
						color: 'secondary',
						icon: 'mdi-content-copy'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/vacantes/1/copiar',
                    permissions: 'vacancies.create'
				},
				{
					name: 'Candidatos',
					icon: {
						color: 'secondary',
						icon: 'mdi-account-check-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/vacantes/1/candidatos',
                    permissions: 'candidates.show'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Folio',
						align: 'left',
						value: 'id',
						class: 'table-b-border'
					},
                    {
						text: 'Puesto',
						align: 'left',
						value: 'position.name',
						class: 'table-b-border'
					},
                    {
						text: 'Empresa',
						align: 'left',
						value: 'branch_office.company.name',
						class: 'table-b-border'
					},
                    {
						text: 'Fecha de solicitud',
						align: 'center',
						value: 'request_date',
						class: 'table-b-border'
					},
                    {
                        text: 'Disponibles',
                        align: 'center',
                        value: 'available_positions',
                        class: 'table-b-border'
                    },
                    {
                        text: 'Estatus',
                        align: 'center',
                        value: 'statusJobs',
                        class: 'table-b-border'
                    },
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '9%',
						class: 'table-b-border'
					}
				],
				items: [],
				search: '',
				loading: false
			},
			modalProps: {
				visible: false,
				width: '500',
				title: 'Hola',
				text: 'Adiós',
				buttons: [
					{
						text: 'Cancelar',
						color: 'error',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: []
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'primary--text mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: []
					}
				]
			},
			snackbar: {
				visible: false,
				color: '',
				text: '',
				timeout: '-1'
			},
            createBtnData: {
                text: "Nueva vacante",
                icon: "mdi-plus",
				to: "JobsCreate",
				block: true,
                click: ()=>{}
            }
		}
	},

	mounted(){
		this.index()
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
		index() {
			this.tableProps.loading = true;

			//call api
			this.$api.jobs.index()
				.then((resp) =>{
					//console.log(resp, "desde areas");
					this.tableProps.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
						x.actions[0].action = { name: 'JobsEdit', params: { id: x.id } }
						x.actions[1].action = { name: '', params: { id: x.id } }
						x.actions[2].action = { name: 'Candidates', params: { jobId: x.id } }
						

						x['parameters'] = { id: x.id, name: x.name};
						x.statusJobs = "Abierta";
						if(x.available_positions == 0)
							x.statusJobs = 'Cubierta';

                        x.request_date = Utils.globalDateFormat(x.request_date);
						
						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error jobs index");
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
			//console.log(test);
			
		},

        getColorStatusJobs (statusJobs) {
            if(statusJobs == "Abierta")
				return 'caution';
			if(statusJobs == "Cubierta")
				return 'success';
        },

    }
}
</script>