<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-6">
                        <div class="font-weight-bold body-1">
                            Nuevo Cuestionario
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-form ref="form" @submit.prevent="save">
                            <questionary-form ref="QuestionaryForm" :values="form" @save="save" :stepIn="step" :stepClasses="stepClasses" @tabChanged="(val) => this.setStep(val)" :serverErrors="serverErrors"></questionary-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

            </div>

            <v-row justify="center">
				<v-col cols="12" md="6" class="d-flex justify-end">
                    <secondary-button :props="cancelBtnData" class="mr-2"></secondary-button>
					<primary-button :props="saveBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import QuestionaryForm from "./Form.vue";
import Utils from '../../../helpers/Utils';
export default {
    components: {
        'questionary-form': QuestionaryForm,
    },
    
	data(){
		return {
            serverErrors: {},
            step: '1',
            stepClasses: {st1: '', st2: '', st3: ''},
            loading: false,
            form: {
                /**GENERAL INFORMATION */
                name: '',
                apply_for: [],
                /**QUESTIONS */
                questions: [],

            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Questionary",	
                click: () => {}
            },
            saveBtnData: {
                text: "Continuar",
                icon: "mdi-content-save-outline",	
                click: this.nextStep,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    watch: {
        step: function(){
            this.saveBtnData.text = "Continuar";
            if(this.step == 2)
                this.saveBtnData.text = "Guardar cambios";
        }
    },
    mounted(){
       
    },
	methods: {
        setStep(val)
        {
            this.step = val + '';
        },
        checkErrorClass(){
            this.clearTabError();
            let errors = Object.entries(this.$refs.form.errorBag);
            //console.log(this.$refs.form.inputs, errors)
            let inputs = this.$refs.form.inputs;
            //onsole.log(inputs[0].label);
            for (let index = 0; index < errors.length; index++) {
                if(errors[index][1]){
                    let found = inputs.find((inpt) => inpt._uid == errors[index][0])
                    //console.log(this.getParentName(found))
                    let parentName = this.getParentName(found);
                    if(parentName == 'p1')
                        this.stepClasses = {...this.stepClasses, st1: 'tab-error'};
                    if(parentName == 'p2')
                        this.stepClasses = {...this.stepClasses, st2: 'tab-error'};
                    
                }
            }
        },
        
        setErrorClassByServer(errors)
        {
            if("name" in errors)
                this.stepClasses = {...this.stepClasses, st1: 'tab-error'};
            if('apply_for' in errors)
                this.stepClasses = {...this.stepClasses, st1: 'tab-error'};
        },


        /**
         * Remove error class to tabs
         */
        clearTabError(){
            this.stepClasses = {...this.stepClasses, st1: '', st2: ''};
        },

        /**
         * Get name attrubute from html parent
         */
        getParentName(input){
            if("name" in input.$parent.$attrs)
                return input.$parent.$attrs.name;
            else
                return this.getParentName(input.$parent);
            
        },

		validate () {
            console.log(this.serverErrors)
            // return true;
            //questions list
            if(this.form.questions.length == 0 && this.step == 2){
                this.serverErrors[`questions`] = 'Al menos una preguta';
                this.$refs.form.validate()
                return false;
            }
           
            for (let index = 0; index < this.form.questions.length; index++) {
                if(this.form.questions[index].type == 'multi'){
                    if(this.form.questions[index].option_list.length == 0){
                        
                        this.serverErrors[`questions.${index}.option_list`] = "Al menos una opción";
                        this.$refs.form.validate()
                        return false;
                    }
                }
            }
            // if(this.form.apply_for.length == 0)
            //     return false;

			return this.$refs.form.validate()
		},
        nextStep(){
            this.checkErrorClass();
            // this.$refs.QuestionaryForm.rules.email = false
            // console.log(this.$refs.QuestionaryForm.rules.email);
            if(this.step == '1'){
                
                if(this.validate()){
                    this.step = '2';
                    return;
                }
            }else if(this.step == '2'){
                this.save();
                return;
            }

            this.saveBtnData.loading = false;
            this.$store.state.loading = false;
            this.$store.dispatch('snackbarError', `Formulario inválido`);
           
        },
        save(){
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
           
            console.log(this.form);
            if(this.validate()){
                
                let form = JSON.parse(JSON.stringify(this.processRequest(this.form)))
                
                this.$api.questionaries.store(form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'Questionary'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            if(x.startsWith('apply_for')){
                                                this.serverErrors['apply_for'] = this.getApplyForMsg(form.apply_for, {key:x, text:y});
                                            }else{
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                
                                            }
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors);
                        this.setErrorClassByServer(this.serverErrors);
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
               
                this.saveBtnData.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },


        getApplyForMsg(applyData, errorMessage)
        {
            try {
                let index = errorMessage.key.split(".")[1];
                let company = applyData[index].id?.replace("-"," ");
                return `${company}: ${errorMessage.text}`;
            } catch (error) {
                return ''+errorMessage.text;
            }
            
        },

        processRequest(data)
        {
            let newData = JSON.parse(JSON.stringify(data));

            newData.apply_for = newData.apply_for.map((itm) =>{ return {id: itm}})

            return newData;
        }
    },
}
</script>

<style>

</style>