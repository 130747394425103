<template>
    <div>
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.name = v.trim(); delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="rules.name" 
                    label="Nombre *"
                    :errorMessages="serverErrors.name"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <autocompleteOneForm
                    label="Empresa *"
                    :valueInput="values.company_id"
                    :loading="loadingCompanies"
                    :rule="rules.company_id"
                    :items="serverItems.companies"
                    @valueChange="(v) => {values.company_id = v; delete serverErrors.company_id}"
                    :errorMessages="serverErrors.company_id"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <location-information
                    :valuesInput="values"
                    :rules="rules"
                    :serverErrors="serverErrors"
                ></location-information>
            </v-col>
        </v-row>
       
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.street = v.trim(); delete serverErrors.street}" 
                    :valueInput="values.street" 
                    :rule="rules.street" 
                    label="Calle *"
                    :errorMessages="serverErrors.street"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <textFieldForm 
                    @valueChange="(v) => {values.ext_number = v.trim(); delete serverErrors.ext_number}" 
                    :valueInput="values.ext_number" 
                    :rule="rules.ext_number" 
                    label="No. Exterior"
                    :errorMessages="serverErrors.ext_number"
                ></textFieldForm>
            </v-col>
            <v-col >
                 <textFieldForm 
                    @valueChange="(v) => {values.int_number = v.trim(); delete serverErrors.int_number}" 
                    :valueInput="values.int_number" 
                    :rule="rules.int_number" 
                    label="No. Interior *"
                    :errorMessages="serverErrors.int_number"
                ></textFieldForm>
            </v-col>
        </v-row>
    
                


    </div>
</template>

<script>
import LocationInformation from '../../../components/LocationInformation.vue'

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
    components: {
        'location-information': LocationInformation,
    },
   
    data(){
        return {
            loadingCompanies: false,
            rules: {
               
				name: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
				company_id: [
                    v => !!v || 'El campo es requerido'
				],
                country: [
                    v => !!v || 'El campo es requerido'
				],

                street: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],


                ext_number: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                int_number: [
                    v => {
                        if(v == null)
                            return true;
                        if(v.length > 0){
                            try {
                                if(v.trim() == "")
                                    return "Campo requerido";
                            } catch (error) {
                                console.log(error);
                            }

                        }
                        return true;
                    }
                ],

                zip_code: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],

                neighborhood:[
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],

                city:[
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],

                state:[
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],

               


				
            },
            serverItems: {
                companies: [],
                countries: [{text:"país 1", value: "1"}],
                employees: [{text:"empleado1", value: "1"}, {text:"empleado2", value: "2"}],
                degrees: [{text: "nivel 1", value: "1"}]
            },

          
        }
    },
    watch: {
        values: function () {
            console.log(this.values);
        }
    },
    mounted(){
        this.getCompanies()
    },
    methods: {
        getCompanies()
        {
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
                .then((resp) =>{
                    console.log(resp, "desde form");
                    this.serverItems.companies = resp.data.map((item) => {
                        return {text: item.name, value: item.id};
                    });
                })
                .catch((error) =>{
                    console.log(error, "error desde form areas");
                })
                .finally(() => {
                    this.loadingCompanies = false;
                })
            ;
        }
    }
}
</script>

<style>

</style>