<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-5">
                        <div class="font-weight-bold body-1">
                            Detalles Locación - {{items[0].valor}}
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="5" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>

            </div>

            <v-row justify="center">
				<v-col cols="12" md="5" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
export default {
    components: {
        DetailsItem
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            items: [
                { concepto: 'Código', index: 'id', valor: null },
                { concepto: 'Nombre', index: 'name', valor: null},
                { concepto: 'Empresa', index: 'company.name', valor: null},
                { concepto: 'país', index: 'countryName', valor: null},
                { concepto: 'Estado', index: 'stateName', valor: null},
                { concepto: 'Municipio', index: 'cityName', valor: null},
                { concepto: 'Código postal', index: 'zip_code', valor: null},
                { concepto: 'Colonia', index: 'neighborhoodName', valor: null},
                { concepto: 'Calle', index: 'street', valor: null},
                { concepto: 'No. Exterior', index: 'ext_number', valor: null},
                { concepto: 'No. Interior', index: 'int_number', valor: null},
                
                
            ],
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Locations",	
                click: () => {}
            },
         
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.locations.get(this.id)
			.then((response) => {
                this.items.forEach((y) => {
                    y.valor = response.data[y.index]??''
                });
                //set company

                //let found = this.items.indexOf((itm) => itm.index == 'company.name');
                let found = this.items.map(function(e) { return e.index; }).indexOf('company.name');
                if(found != undefined)
                    this.items[found].valor = response.data.company.name;

               this.getLocationData(response);
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},

        getLocationData(response)
        {
            if(response.data.country == 'MX'){
                this.$locationsApi.locations.getNeighborhoodDetails(response.data.neighborhood)
                .then((resp) => {
                    let found = this.items.map(function(e) { return e.index; }).indexOf('countryName');
                    if(found != undefined)
                        this.items[found].valor = 'México';
                    found = this.items.map(function(e) { return e.index; }).indexOf('cityName');
                    if(found != undefined)
                        this.items[found].valor = resp.data.city.name;
                    found = this.items.map(function(e) { return e.index; }).indexOf('neighborhoodName');
                    if(found != undefined)
                        this.items[found].valor = resp.data.name;
                    
                });
                this.$locationsApi.locations.getStates()
                    .then((resp)=> {
                        let foundState = resp.data.filter((it) => it.value == response.data.state)[0];
                        if(foundState != undefined){
                        {
                            let found = this.items.map(function(e) { return e.index; }).indexOf('stateName');
                            if(found != undefined)
                                this.items[found].valor = foundState.text;
                            }
                        }
                    })
                ;
            }else{
                this.$locationsApi.locations.getCountries()
                    .then((resp)=> {
                        let foundState = resp.data.filter((it) => it.value == response.data.country)[0];
                        if(foundState != undefined){
                        {
                            let found = this.items.map(function(e) { return e.index; }).indexOf('countryName');
                            if(found != undefined)
                                this.items[found].valor = foundState.text;
                            }
                        }
                        let found = this.items.map(function(e) { return e.index; }).indexOf('stateName');
                        if(found != undefined)
                            this.items[found].valor = response.data.state;
                        found = this.items.map(function(e) { return e.index; }).indexOf('cityName');
                        if(found != undefined)
                            this.items[found].valor = response.data.city;
                        found = this.items.map(function(e) { return e.index; }).indexOf('neighborhoodName');
                        if(found != undefined)
                            this.items[found].valor = response.data.neighborhood;
                    })
                ;
                
            }
            
        }
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 