<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-5">
                        <div class="font-weight-bold body-1">
                            Asignar responsable
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <v-form ref="form" @submit.prevent="save">
                            <assign-form ref="AssignForm" :values="form" @save="save" :serverErrors="serverErrors"></assign-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

            </div>

            <v-row justify="center">
				<v-col cols="12" md="5" class="d-flex justify-end">
                    <secondary-button :props="cancelBtnData" class="mr-2"></secondary-button>
					<primary-button :props="saveBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import PrimaryButton from '../../components/primaryButton.vue';
import AssignForm from "./AssignForm.vue";
import Utils from "../../helpers/Utils";
export default {
    components: {
        'assign-form': AssignForm,
        PrimaryButton,
    },
	data(){
		return {
            serverErrors: {},
            loading: false,
            id: this.$route.params.id,
            form: {
                company: '',
                employee_id: '',
               
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Inventory",	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        this.index();
    },
	methods: {
        index(){
            this.loading = true;
            this.$store.state.loading = true;
			this.$api.inventory.get(this.id)
			.then((response) => {
                //TODO company
                //
                this.form.company = Utils.getObjectValue(response.data, 'employee.current_contract.branch_office.company.id');

                //employee
                this.form.employee_id = response.data.employee.id??'';
                console.log(this.form);
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
        },
		validate () {
			return this.$refs.form.validate();
		},
        save(){
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
           
            // this.$store.state.overlay = true

            if(this.validate()){
                
                // let form = JSON.parse(JSON.stringify(this.form))
                let request = this.prepareRequest(this.form);
                this.$api.inventory.lendOut(this.id, request)
                    .then((response) => {
                        console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha asignado correctamente`);
                        this.$router.push({name: 'Inventory'});
                    })
                    .catch((error) => {
                        console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                this.saveBtnData.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },

        /**
         * Process data
         */
        prepareRequest(data){
            let newData = JSON.parse(JSON.stringify(data));
            if(data.employee_id == '')
                newData.employee_id = null;
            return newData;
        }
    },
}
</script>

<style>

</style>