<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text class="pt-0 px-0">
                <div class="attendances-inputs-container">
                    <div class="attendances-inputs-section-1">
                        <div class="attendances-inputs-section-1-input-1" >
                            <AlternativeAutocompleteOne
                                label="Empresa"
                                :items="serverItems.companies"
                                :valueInput="selectedBusiness"
                                :loading="loadingCompanies"
                                @valueChange="(v) => {selectedBusiness = v; this.index()}"
                            ></AlternativeAutocompleteOne>
                        </div>
                        <div class="attendances-inputs-section-1-input-2" >
                            <AlternativeAutocompleteOne
                                label="Año"
                                :items="years"
                                :valueInput="selectedYear"
                                :loading="false"
                                @valueChange="(v) => {selectedYear = v; this.index()}"
                            ></AlternativeAutocompleteOne>
                        </div>
                        <div class="attendances-inputs-section-1-input-3" >
                            <AlternativeAutocompleteOne
                                :items="weeks"
                                label="Semana"
                                :valueInput="selectedWeek"
                                :loading="false"
                                @valueChange="(v) => {selectedWeek = v; this.index()}"
                            ></AlternativeAutocompleteOne>
                        </div>
                        <!-- <v-spacer></v-spacer> -->
                        <div class="ml-0 ml-md-0 attendances-inputs-section-1-input-4">
                            <alternativeButton
                            class="mr-1 btn x"
                            :props="returnBtnData"
                            ></alternativeButton>
                            <alternativeButton
                                class="btn"
                                :props="nextBtnData"
                            ></alternativeButton>
                        </div>
                    </div>

                    <div class="attendances-inputs-section-2">
                        <div v-permission="'attendances.create'" class=" ">
                            <primaryButton  :props="uploadBtnData"></primaryButton>
                        </div>
                        
                        <div  class="attendances-buttons-container">
                            <iconButton
                                :props="{
                                color:'primary',
                                icon:'mdi-information-outline',
                                iconClass: '',
                                iconColor:'black',
                                height:'35',
                                click: () => {openFormatModal()}
                                }"
                            ></iconButton>
                                <div class="attendances-view-button">
                                    <v-btn-toggle
                                    v-model="indexView"
                                    class=""
                                    mandatory
                                    active-class="attendances-active-view-button"
                                    color="white"
                                    group
                                 
                                >
                                <v-btn height="35" outlined class="btn-disabled " active-class="btn-active primary">
                                    <v-icon >mdi-view-dashboard-outline</v-icon>
                                </v-btn>
                                <v-btn height="35" outlined class="btn-disabled " active-class="btn-active primary">
                                    <v-icon>mdi-format-list-bulleted</v-icon>
                                </v-btn>
                                
                            </v-btn-toggle>
                                </div>
                                
                            
                        </div>
                                  
                    </div>
                </div>
				

                <!-- TABLE -->
				<v-row v-if="indexView == 0">
					<v-col>
						<datatable :props="tableProps" @method_handler="method_handler">
                            <template v-slot:[`item.monday`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.monday == 'FI' ? 'red--text': ''">
                                            {{item.monday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.monday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.monday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.monday)}}</span>
                                </v-tooltip>
								
							</template> 
                            <template v-slot:[`item.tuesday`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.tuesday == 'FI' ? 'red--text': ''">
                                            {{item.tuesday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.tuesday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.tuesday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.tuesday)}}</span>
                                </v-tooltip>
								
							</template> 
                            <template v-slot:[`item.wednesday`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.wednesday == 'FI' ? 'red--text': ''">
                                            {{item.wednesday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.wednesday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.wednesday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.wednesday)}}</span>
                                </v-tooltip>
								
							</template> 
                            <template v-slot:[`item.thursday`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.thursday == 'FI' ? 'red--text': ''">
                                            {{item.thursday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.thursday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.thursday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.thursday)}}</span>
                                </v-tooltip>
								
							</template> 
                            <template v-slot:[`item.friday`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.friday == 'FI' ? 'red--text': ''">
                                            {{item.friday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.friday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.friday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.friday)}}</span>
                                </v-tooltip>
								
							</template> 
                            <template v-slot:[`item.saturday`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.saturday == 'FI' ? 'red--text': ''">
                                            {{item.saturday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.saturday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.saturday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.saturday)}}</span>
                                </v-tooltip>
								
							</template> 
                            <template v-slot:[`item.sunday`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.sunday == 'FI' ? 'red--text': ''">
                                            {{item.sunday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.sunday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.sunday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.sunday)}}</span>
                                </v-tooltip>
								
							</template> 
						</datatable>
					</v-col>
				</v-row>

                <v-row v-if="indexView == 1">
					<v-col>
						<datatable :props="tableTimeProps" @method_handler="method_handler">
                            <template v-slot:[`item.monday`]="{ item }">
                                <v-tooltip bottom v-if="item.mondayIsTime == false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.monday == 'FI' ? 'red--text': ''">
                                            {{item.monday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.monday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.monday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.monday)}}</span>
                                </v-tooltip>
                                <span v-if="item.mondayIsTime == true" class="prewrap">{{item.monday}}</span>
							</template> 
                            <template v-slot:[`item.tuesday`]="{ item }">
                                <v-tooltip bottom v-if="item.tuesdayIsTime == false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.tuesday == 'FI' ? 'red--text': ''">
                                            {{item.tuesday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.tuesday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.tuesday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.tuesday)}}</span>
                                </v-tooltip>
                                <span v-if="item.tuesdayIsTime == true" class="prewrap">{{item.tuesday}}</span>
							</template> 
                            <template v-slot:[`item.wednesday`]="{ item }">
                                <v-tooltip bottom v-if="item.wednesdayIsTime == false" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.wednesday == 'FI' ? 'red--text': ''">
                                            {{item.wednesday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.wednesday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.wednesday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.wednesday)}}</span>
                                </v-tooltip>
                                <span v-if="item.wednesdayIsTime == true" class="prewrap">{{item.wednesday}}</span>
							</template> 
                            <template v-slot:[`item.thursday`]="{ item }">
                                <v-tooltip bottom v-if="item.thursdayIsTime == false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.thursday == 'FI' ? 'red--text': ''">
                                            {{item.thursday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.thursday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.thursday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.thursday)}}</span>
                                </v-tooltip>
								<span v-if="item.thursdayIsTime == true" class="prewrap">{{item.thursday}}</span>
							</template> 
                            <template v-slot:[`item.friday`]="{ item }">
                                <v-tooltip bottom v-if="item.fridayIsTime == false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.friday == 'FI' ? 'red--text': ''">
                                            {{item.friday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.friday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.friday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.friday)}}</span>
                                </v-tooltip>
								<span v-if="item.fridayIsTime == true" class="prewrap">{{item.friday}}</span>
							</template> 
                            <template v-slot:[`item.saturday`]="{ item }">
                                <v-tooltip bottom v-if="item.saturdayIsTime == false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.saturday == 'FI' ? 'red--text': ''">
                                            {{item.saturday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.saturday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.saturday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.saturday)}}</span>
                                </v-tooltip>
								<span v-if="item.saturdayIsTime == true" class="prewrap">{{item.saturday}}</span>
							</template> 
                            <template v-slot:[`item.sunday`]="{ item }">
                                <v-tooltip bottom v-if="item.sundayIsTime == false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <!-- <div :class="item.sunday == 'FI' ? 'red--text': ''">
                                            {{item.sunday}}
                                        </div> -->
                                        <v-chip
                                            :color="getChipColor(item.sunday)"
                                            label
                                            pill
                                            class="chip-day-type"
                                        > {{item.sunday}}</v-chip>
                                        </span>
                                    </template>
                                    <span>{{getTypeValue(item.sunday)}}</span>
                                </v-tooltip>
								<span v-if="item.sundayIsTime == true" class="prewrap">{{item.sunday}}</span>
							</template> 
						</datatable>
					</v-col>
				</v-row>

			</v-card-text>
		</v-card>

        <!-- FORMAT DOWNLOAD -->
        <modal :props="modalFormatProps" @modalResponse="method_handler">
            <!-- Body -->
           
            <template slot="cardBody">
                <span>1.- Conserva todos los encabezados en el orden presentado y sin modificarlos.</span><br>
                <span>2.- Llena la información de acuerdo a los campos solicitado y no utilices comas en ninguno de los campos.</span><br>
                <span>3.- El archivo debe estar en formato CSV</span><br>
                <span>4.- La fecha debe escribirse en formato DD/MM/AAAA</span><br>
                <span>5.- Las horas de entrada y salida son en formato 24 horas HH:MM</span><br>
                <span>6.-  A continuación se muestra una línea de ejemplo acorde al formato.</span><br>
                <datatable :props="tableFormatProps" @method_handler="method_handler">
                    
                </datatable>
			</template>
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end" >
					<v-col cols="12" sm="3">
						<secondaryButton
                            :props="donwloadFormatModal"
						></secondaryButton>
					</v-col>
                    
					<v-col cols="12" sm="2"  class="mr-sm-4">
						<primaryButton
						:props="acceptFormatModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>

        <!-- <modal :props="modalProps" @modalResponse="method_handler"></modal> -->
        <input 
            ref="uploader" 
            class="d-none" 
            type="file" 
            @change="onFileChanged"
        >
		
	</div>
</template>
<script>
import Object2Form from '../../../helpers/Object2Form';
import PageStateManager from '../../../helpers/PageStateManager';
import Utils from '../../../helpers/Utils';
export default {
	components: {
    },
    data(){
		return {
            selectedFile: [],
            indexView: 0,
			tableProps: {
				headers: [
                    {
						text: 'No. Empleado',
						align: 'left',
						value: 'employe_number',
						class: 'table-b-border'
					},
					{
						text: 'Nombre',
						align: 'left',
						value: 'name',
						class: 'table-b-border'
					},
                    {
						text: 'Faltas',
						align: 'center',
						value: 'absent',
						class: 'table-b-border'
					},
                    {
						text: 'Lunes',
						align: 'left',
						value: 'monday',
						class: 'table-b-border'
					},
                    {
						text: 'Martes',
						align: 'left',
						value: 'tuesday',
						class: 'table-b-border'
					},
                    {
                        text: 'Miércoles',
                        align: 'left',
                        value: 'wednesday',
                        class: 'table-b-border'
                    },
                    {
                        text: 'Jueves',
                        align: 'left',
                        value: 'thursday',
                        class: 'table-b-border'
                    },
                    {
                        text: 'Viernes',
                        align: 'left',
                        value: 'friday',
                        class: 'table-b-border'
                    },
                    {
                        text: 'Sábado',
                        align: 'left',
                        value: 'saturday',
                        class: 'table-b-border'
                    },
                    {
                        text: 'Domingo',
                        align: 'left',
                        value: 'sunday',
                        class: 'table-b-border'
                    },
				],
				items: [],
				search: '',
				loading: false
			},
            tableTimeProps: {
				headers: [
                    {
						text: 'No. Empleado',
						align: 'left',
						value: 'employe_number',
						class: 'table-b-border'
					},
					{
						text: 'Nombre',
						align: 'left',
						value: 'name',
						class: 'table-b-border'
					},
                    {
						text: 'Laborado',
						align: 'center',
						value: 'hours',
						class: 'table-b-border'
					},
                    {
						text: 'Extra',
						align: 'center',
						value: 'overtime',
						class: 'table-b-border'
					},
                    {
						text: 'Lunes',
						align: 'left',
						value: 'monday',
						class: 'table-b-border'
					},
                    {
						text: 'Martes',
						align: 'left',
						value: 'tuesday',
						class: 'table-b-border'
					},
                    {
                        text: 'Miércoles',
                        align: 'left',
                        value: 'wednesday',
                        class: 'table-b-border'
                    },
                    {
                        text: 'Jueves',
                        align: 'left',
                        value: 'thursday',
                        class: 'table-b-border'
                    },
                    {
                        text: 'Viernes',
                        align: 'left',
                        value: 'friday',
                        class: 'table-b-border'
                    },
                    {
                        text: 'Sábado',
                        align: 'left',
                        value: 'saturday',
                        class: 'table-b-border'
                    },
                    {
                        text: 'Domingo',
                        align: 'left',
                        value: 'sunday',
                        class: 'table-b-border'
                    },
				],
				items: [],
				search: '',
				loading: false
			},
			tableFormatProps: {
				headers: [
                    {
						text: 'numero_empleado',
                        sortable: false,
						align: 'left',
						value: 'number',
						class: 'table-b-border'
					},
					{
						text: 'fecha',
                        sortable: false,
						align: 'center',
						value: 'date',
						class: 'table-b-border'
					},
                    {
						text: 'hora_entrada',
                        sortable: false,
						align: 'center',
						value: 'hour_start',
						class: 'table-b-border'
					},
                    {
						text: 'hora_salida',
                        sortable: false,
						align: 'center',
						value: 'hour_end',
						class: 'table-b-border'
					},
                    
				],
				items: [{number: "0000000073", date: "25/10/2023", hour_start:"9:00", hour_end:"18:00"}],
				search: '',
				loading: false,
                hideFooter:true,
                elevation: '0'
			},
			
			snackbar: {
				visible: false,
				color: '',
				text: '',
				timeout: '-1'
			},
            uploadBtnData: {
                text: "Subir asistencias",
                icon: "mdi-progress-upload",
				to: "",
				block: true,
                loading: false,
                click: ()=>{this.uploadFile()}
            },
            returnBtnData: {
                height: 38,
                text: "<",
                icon: "mdi-chevron-left",
				to: "",
				block: false,
                click: ()=>{this.pastWeek()}
            },
            nextBtnData: {
                height: 38,
                text: ">",
                icon: "mdi-chevron-right",
				to: "",
				block: false,
                click: ()=>{this.nextWeek()}
            },

            modalFormatProps: {
				visible: false,
				width: '700',
				title: 'Formato de Asistencias',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				
			},

            donwloadFormatModal:{
                text: "Descargar",
                classes: "",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
                
                click: ()=>{this.downloadExample()}
            },
            acceptFormatModal: {
                text: "OK",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'black',
                classes: "justi-center",
				loading:false,
                click: ()=>{this.modalFormatProps.visible = false}
            },

            serverItems: {
                companies: []
            },

            loadingCompanies: false,

            years: [],
            actualYear: new Date().getFullYear(),
            selectedYear: new Date().getFullYear().toString(),

            weeks: [],
            actualWeek: '',
            selectedWeek: '',
            selectedBusiness: PageStateManager.getState(this.$router.history.current.path, 'selectedBusiness')??'',
		}
	},
    watch: {
        selectedYear: function(v){
           this.getWeeks(v)
        },
        [`selectedBusiness`]: function () {
            // console.log(this.selectedBusiness)
            PageStateManager.setState(this.$router.history.current.path, 'selectedBusiness', this.selectedBusiness)
        },
    },

	mounted(){
        this.getYearSelect();
        this.getWeeks();
		this.getSelectedDates();
        this.getCompanies();
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
        getNameWeek(dayWeek){
            switch (dayWeek) {
                case 0:
                    return 'sunday';
                case 1:
                    return 'monday';
                case 2:
                    return 'tuesday';
                case 3:
                    return 'wednesday';
                case 4:
                    return 'thursday';
                case 5:
                    return 'friday';
                case 6:
                    return 'saturday';
            
                default:
                    return 'sunday'
            }
        },
        getResultText(isAbsence)
        {
            switch (isAbsence) {
                case true:
                    return 'F';
                case false:
                    return 'A';
                  
                default:
                    return 'N/A'
            }
        },
        calculateAbsent(type)
        {
            switch (type) {
                case 'FI':
                
                    return true;
                default:
                    return false;
            }
        },

        getTypeValue(type)
        {
            switch (type) {
                case 'AA':
                    return "Asistencia"
                case 'FI':
                    return "Falta Injustificada"
                case 'FJ':
                    return "Falta justificada"
                case 'DE':
                    return "Descanso Lunes - Domingo"
                case 'FE':
                    return "Feriado"
                case 'IN':
                    return "Incapacidad"
                case 'PG':
                    return "Permiso con goce"
            
                case 'PS':
                    return "Permiso sin goce"
            
                case 'DX':
                    return "Descanso Laborado"
            
                case 'VA':
                    return "Vacaciones"
            
                default:
                    return "N/A"
            }
        },
        getChipColor(type){
            switch (type) {
                case 'AA':
                    return "#B1DE8C"
                case 'FI':
                    return "#FF6E6E"
                case 'FJ':
                    return "#B1DE8C"
                case 'DE':
                    return "#E4E4E4"
                case 'FE':
                    return "#E4E4E4"
                case 'IN':
                    return "#F8CF5E"

                case 'PG':
                    return "#B1DE8C"
            
                case 'PS':
                    return "#9CC8E8"
            
                case 'DX':
                    return "#87C77F"
            
                case 'VA':
                    return "#E4E4E4"

                case '/':
                    return "#F8FAFF";
            
                default:
                    return "#B1DE8C"
            }
        },
		index() {
            
			this.tableProps.loading = true;
			this.tableTimeProps.loading = true;

           
			// this.tableProps.items.push({
            //     id: '0002',
            //     name: 'Jacqueline López',
            //     absent: '1',
            //     monday: 'A', 1
            //     tuesday: 'A', 2
            //     wednesday: "A", 3
            //     thursday: 'A', 4
            //     friday: 'F', 5
            //     saturday: 'A', 6
            //     sunday: 'A', 0
            // });
            this.tableProps.items = [];
            this.tableTimeProps.items = [];

            const dates = this.getSelectedDates()
            //call api
			this.$api.attendances.getByDateBusiness(dates.start_date, dates.end_date, this.selectedBusiness)
				.then((resp) =>{
					// console.log(resp);
					this.tableProps.items = JSON.parse(JSON.stringify(resp.data)).map((x) => {
						x.monday = x.tuesday = x.wednesday = x.thursday = x.friday = x.saturday = x.sunday = "N/A"
                        //process data
                        let attDate = "";
                        let dateSplit = []
                        let countAbsence = 0;
                        x.attendances.forEach(atte => {
                            if(atte.type == null)
                                atte.type = "/";
                            dateSplit = atte.date.split("-");
                            attDate = new Date(dateSplit[0], dateSplit[1]-1, dateSplit[2]);
                            // console.log(attDate.getDay(), this.getNameWeek(attDate.getDay()));
                            x[`${this.getNameWeek(attDate.getDay())}`] = atte.type;
                            countAbsence = countAbsence + ( this.calculateAbsent(x[`${this.getNameWeek(attDate.getDay())}`]) ? 1 : 0 )
                        });

                        x.absent = countAbsence;

                        

						
						return x
					});
                   
                    this.tableTimeProps.items = JSON.parse(JSON.stringify(resp.data)).map((x) => {
						x.monday = x.tuesday = x.wednesday = x.thursday = x.friday = x.saturday = x.sunday = "N/A"
						x.mondayIsTime = x.tuesdayIsTime = x.wednesdayIsTime = x.thursdayIsTime = x.fridayIsTime = x.saturdayIsTime = x.sundayIsTime = false
                       
                        //process data
                        let attDate = "";
                        let dateSplit = []
                        let countHours = 0;
                        x.attendances.forEach(atte => {
                            dateSplit = atte.date.split("-");
                            attDate = new Date(dateSplit[0], dateSplit[1]-1, dateSplit[2]);

                            x[`${this.getNameWeek(attDate.getDay())}IsTime`] = false;
                            // atte.check_in_time = "09:00:00";
                            // atte.check_out_time = "10:00:00";
                            // atte.type = "AA";
                            if(atte.type == null)
                                atte.type = "/";

                            if(atte.check_in_time != null || atte.check_out_time != null){
                                x[`${this.getNameWeek(attDate.getDay())}IsTime`] = true;
                            }
                           
                            // console.log(attDate.getDay(), this.getNameWeek(attDate.getDay()));
                            if(x[`${this.getNameWeek(attDate.getDay())}IsTime`]){
                                x[`${this.getNameWeek(attDate.getDay())}`] = `${atte.check_in_time??""}\n${atte.check_out_time??"-"}`
                            }else{
                                x[`${this.getNameWeek(attDate.getDay())}`] = atte.type;
                            }
                            countHours = countHours + ( this.calculateHours(atte))
                        });
                        x.hours = countHours.toFixed(2)??0;

                        //overtime
                        x.overtime = parseFloat(x?.overtime??0).toFixed(2);
                        if(x.overtime <= 0)
                            x.overtime = "-";
						
						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error desde areas");
				})
				.finally(() => {
					this.tableProps.loading = false;
                    this.tableTimeProps.loading = false;
				})
			;
            
			
		},

        calculateHours(atte){
            if(atte.check_in_time != null && atte.check_out_time != null){
                const dateSplit = atte.date.split("-");
                const timeSplitStart = atte.check_in_time.split(":");
                const timeSplitEnd = atte.check_out_time.split(":");
                let attDateStart = new Date(dateSplit[0], dateSplit[1]-1, dateSplit[2], timeSplitStart[0], timeSplitStart[1]);
                let attDateEnd = new Date(dateSplit[0], dateSplit[1]-1, dateSplit[2], timeSplitEnd[0], timeSplitEnd[1]);
                return Math.abs(attDateStart - attDateEnd) / 36e5;
            }

            return 0;
        },

        getYearSelect()
        {
            this.years.push({value:"2021", text: "2021"});
            this.years.push({value:"2022", text: "2022"});
            this.years.push({value:"2023", text: "2023"});
            this.years.push({value:"2024", text: "2024"});
        },

        getWeeks()
        {
            const utils = new Utils();
            // console.log(this.selectedYear)
            //actual
            this.weeks = utils.weeksYear(this.selectedYear);
            // console.log(this.weeks);
            const actual = this.weeks.filter(w => w.active == true)[0];
            
            if(actual != undefined && actual != null)
            {
                this.actualWeek = actual.value;
                this.selectedWeek = actual.value;
            }
        },

        nextWeek()
        {
            let index = this.weeks.findIndex(w => w.value == this.selectedWeek)
            if((index + 1) >= this.weeks.length){
                index = 0;
            }else{
                index = index + 1;
            }

            this.selectedWeek = this.weeks[index].value;
            this.index();
        },

        pastWeek()
        {
            let index = this.weeks.findIndex(w => w.value == this.selectedWeek)
            if((index - 1) <= -1){
                index = this.weeks.length - 1;
            }else{
                index = index - 1;
            }

            this.selectedWeek = this.weeks[index].value;
            this.index()
        },

        getSelectedDates()
        {
            const dates = this.selectedWeek.split('|');
            return {start_date: dates[0], end_date:dates[1]};
        },


        getCompanies()
        {
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
                .then((resp) =>{
                    //console.log(resp, "desde form");
                    this.serverItems.companies = resp.data.map((item) => {
                        return {text: item.name, value: item.id};
                    });
                    if(this.selectedBusiness == "")
                        this.selectedBusiness = this.serverItems.companies[0].value;
                    this.index();
                })
                .catch((error) =>{
                    console.log(error, "error desde form areas");
                })
                .finally(() => {
                    this.loadingCompanies = false;
                })
            ;
        },


        uploadFile()
        {
            // let form = 
            // this.$api.attendances.uploadFile(this.file)
			// 	.then((resp) =>{
			// 		console.log(resp);
					
			// 	})
			// 	.catch((error) =>{
			// 		console.log(error, "error desde areas");
			// 	})
			// 	.finally(() => {
			// 		this.tableProps.loading = false;
			// 	})
			// ;

            // this.isSelecting = true;

            // // After obtaining the focus when closing the FilePicker, return the button state to normal
            // window.addEventListener('focus', () => {
            //     this.isSelecting = false
            // }, { once: true });
            
            // Trigger click on the FileInput
            this.$refs.uploader.click();
        },

        onFileChanged(e) {
            this.selectedFile = e.target.files[0];
            this.uploadBtnData.loading = true;
            // Do whatever you need with the file, liek reading it with FileReader
            let form = Object2Form.objectToFormData({file:this.selectedFile},"",[]);
            this.$api.attendances.uploadFile(form)
				.then((resp) =>{
					console.log(resp);
                    this.$store.dispatch('snackbarSuccess', `Se ha cargado correctamente`);
				})
				.catch((error) =>{
                    console.log(error)
                    this.$store.dispatch('snackbarError', `Por favor, verifica que el archivo sea .csv y tenga el formato correcto`);
				})
				.finally(() => {
					this.tableProps.loading = false;
                    this.uploadBtnData.loading = false;
				})
			;

        },


        openFormatModal()
        {
            
            this.modalFormatProps.visible = true;
            
           
            
        },

        downloadExample()
        {
          

            this.modalFormatProps.loading = true;
            this.$api.attendances.downloadFormatExample()
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `example_format.csv`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.modalFormatProps.loading = false;
                    this.$store.state.loading = false;
                    
                })
            ;

        }


    }
}
</script>

<style lang="scss">
   
    .attendances-view-button{
        // button{
        //     border: unset;
        //     border: 2px solid red !important;
        // }
        button{
            margin: 0 !important;
        }
        i{
            color: gray !important;
        }

        .v-btn-toggle {
            gap: 4px;
        }
    }
    // .attendances-view-button .v-btn-toggle {
    //     button{
    //         border: 1px solid var(--v-primary-base) !important;
    //     }
    // }
    .btn-disabled{
        margin-left: 4px !important;
        border: 1px solid gray !important;
        border-radius: 5px !important;
        background-color: red !important;
    }
    .btn-active{
        // border: 1px solid var(--v-primary-base) !important;
        border-radius: 5px !important;
        i{
            color: var(--v-primary-base) !important;
        }
    }

    // .attendances-active-view-button .v-btn-toggle {
    //     button{
    //         border: 5px solid var(--v-primary-base) !important;
    //     }
    // }
    .attendances-active-view-button{
       
        i{
            color: var(--v-primary-base) !important;
        }
    }
   
    .btn{
        min-height: 41px !important;
        
    }

    .chip-day-type{
        min-width: 45px !important;
        justify-content: center !important;
    }


    .attendances{
        &-inputs-container{
            display: flex;
            justify-content: space-between;
            margin-bottom: .8rem;

            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                flex-direction: column;
            }
           
        }
    //     flex-basis: 0;
    // flex-grow: 1;
    // max-width: 100%;

        &-inputs-section-1{
            display: flex;
            gap: 4px;
            // flex-basis: 160%;
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                flex-wrap: wrap;
            }
            & > * {
                @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                   flex-grow: 1;
                   flex-basis: 0;
                   min-width: 100%;
                }
            }

            &-input-1{
                flex: .4;
                @media (min-width: #{map-get($grid-breakpoints, 'xl')}) {
                   flex: .8;
                }
                
            }
            &-input-2{
                // flex-basis: 15%;
                flex: .15;
                @media (min-width: #{map-get($grid-breakpoints, 'xl')}) {
                   flex: .2;
                }
            }
            &-input-3{
                // flex-basis: 25%;
                flex: .25;
            }
            &-input-4{
                flex: .25;
                // flex-basis: 11%;
                @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }

        &-inputs-section-2{
            display: flex;
            gap: 4px;
            justify-content: flex-end;
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
               margin-top: 4px;
            }
        }

        &-buttons-container{
            display: flex;
            gap: 4px;
            align-items: baseline;
        }
    }
</style>