<template #default="{ errorMessages }">
  <div elevation="0" ref="formContainer">
    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab && tab == 2"
        color="primary"
        dark
        fixed
        bottom
        right
        fab
        class="fab-questionary"
        @click="addQuestion()"
      >
        <v-icon class="grey--text text--darken-3">mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-tabs
      v-model="tab"
      centered
      grow
      active-class="font-weight-bold tab-active"
      slider-size="3"
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#1"  :class="`tab-header  ${stClasses.st1}`" >
        1 - Datos Generales
      </v-tab>

      <v-tab href="#2" :class="`tab-header  ${stClasses.st2}`">
        2 - Preguntas
      </v-tab>

    </v-tabs>

    <!-- STEP 1 "Datos generales" -->
    <v-tabs-items v-model="tab">
      <v-tab-item
        value="1"
      >
        <div class="mt-3">
          <v-row>
            <v-col>
              <textFieldForm
                name="p1"
                label="Nombre de la encuenta"
                :valueInput="values.name"
                :rule="rules.name"
                @valueChange="(v) => {values.name = v.trim(); delete serverErrors['name'];}"
                :errorMessages="serverErrors['name']"
                ></textFieldForm>
            </v-col>
          </v-row>

          <v-row>
              <v-col>
                <autocompleteMultipleForm
                  name="p1"
                  label="Empresa a la que aplica"
                  :valueInput="values.apply_for"
                  :rule="rules.apply_for"
                  :items="serverItems.companies"
                  :loading="loadingCompanies"
                  @valueChange="(v) => {values.apply_for = v; delete serverErrors.apply_for}"
                  :errorMessages="serverErrors.apply_for"
                ></autocompleteMultipleForm>
              </v-col>
          </v-row>


        </div>


      </v-tab-item>

      <!--STEP 2  "Documentación" --> 
      <v-tab-item
        value="2"
      >
        <div class="my-3 mx-1">
          <v-row>
            <v-col>
              <primaryButton
                :props="addQBtnData"
              ></primaryButton>
            </v-col>
          </v-row>


          <!--  -->

        
          <v-expand-transition v-for="(item, index) in values.questions" :key="index" appear>
          
           
           
            <questionItem
              name="p2"
              :index="index"
              :valuesInput="item"
              :rules="rules"
              :questionsList="questionsIndex"
              :serverErrors="serverErrors"
              @delete="(v) => {deleteQuestion(v)}"
              @addTo="(v) => {addToItem(v)}"
              @removeTo="(i,v) => {removeToItem(i,v)}"
              @addOption="(v) => {addMultiOption(v);}"
              @removeOption="(i,v) => {removeMultiOptionItem(i,v)}"
            ></questionItem>
           
            
          
         
          </v-expand-transition>
          <p class="my-0 py-0 error--text">{{serverErrors[`questions`]}}</p>
          


          <!--  -->

        </div>
      </v-tab-item>

    </v-tabs-items>



  </div>
</template>


<script>

/* eslint-disable */
export default {
    props: ['values', 'stepIn', 'stepClasses','serverErrors'],
  
    model: {
      event: 'tabChanged'
    },
  
   
    computed: {
        tab: {
            get: function() {
                return this.stepIn
            },
            set: function(value) {
                console.log(value)
                this.$emit('tabChanged', value)
                //this.tab = stepIn;
            }
        },
        stClasses: {
            get: function() {
              return this.stepClasses
            },
            set: function(value) {
              console.log(value)
            //  this.$emit('tabChanged', value)
                //this.tab = stepIn;
            }
        }
    },
    data(){
        return {
          e1: 1,
          fab: false,
          //tab: '',
          show4: false,
          loadingCompanies: false,

          questionsIndex:[],
          
          rules: {
            name: [
                v => {
                    if(v == null || v == undefined)
                        return "Campo requerido";
                    try {
                        if(v.trim() == "")
                            return "Campo requerido";
                    } catch (error) {
                        console.log(error);
                    }
                    return true;
                }
            ],
            type: [
                v => !!v || 'El campo es requerido'
            ],
            apply_for:[
                v => {
                    if(v.length == 0)
                    return 'Al menos uno';
                    return true;
                }
            ],
            question: [
                v => !!v || 'El campo es requerido'
            ],


            action_response: [
                v => !!v || 'El campo es requerido'
            ],

            to: [
                v => !!v || 'El campo es requerido b'
            ],
          

          },


          serverItems: {
            companies: [],


            
          },


          addQBtnData: {
            text: "Agregar nueva pregunta",
            icon: "mdi-plus",
            to: "",
            block: true,
            click: ()=>{ this.addQuestion(); delete this.serverErrors[`questions`]}
          }

          
        }
    },
    mounted(){
      this.generateQuestionsIndexList();
      this.getCompanies();
    },
    methods: {

      /**
       * load companies
       */
      getCompanies()
      {
        this.loadingCompanies = true;
        this.$api.user.getUserBusinessSelect()
				.then((resp) =>{
					console.log(resp, "desde form");
					this.serverItems.companies = resp.data.map((item) => {
                        return {text: item.name, value: item.id};
                    });
				})
				.catch((error) =>{
					console.log(error, "error desde form areas");
				})
				.finally(() => {
          this.loadingCompanies = false;
				})
			  ;
      },

      /**
       * Add question
       */
      addQuestion(){
       
        this.values.questions.push({
          id: + new Date(),
          type: "",
          required: false,
          question: "",

          //binary & multi use
          add_not_apply: "",

          //multi use
          add_other:"",
          option_list:[{option:""}],

          //binary use
          to_list: [],

          //scalar use
          start_tag: '',
          end_tag: '',

          //number use
          unit: ""
        });
        this.generateQuestionsIndexList();
        this.tobottom();
      },

      /**
       * Delete question
       */
      deleteQuestion(v){
        // console.log(this.values)
        this.values.questions.splice(v,1);
        this.generateQuestionsIndexList();
        this.sendValues();
        
        
      },


      /**
       * Add "salto de pregunta" item
       */
      addToItem(v){
        if(this.values.questions[v].to_list.length < 3){
          this.values.questions[v].to_list.push({
            action_response: "",
            to: ""
          });
        }
      },

      /**
       * Remove "salto de pregunta" item
       */
      removeToItem(index,toIndex){
        this.values.questions[index].to_list.splice(toIndex,1);
        this.sendValues();
      },


      /**
       * Add option item for multi question type
       */
      addMultiOption(v)
      {
        this.values.questions[v].option_list.push({
          option: "",
        });
      },

      /**
       * Remove option item for multi question type
       */
      removeMultiOptionItem(index, optionIndex)
      {
        if(this.values.questions[index].option_list.length == 1)
          return;
        this.values.questions[index].option_list.splice(optionIndex,1);
        this.sendValues();
      },



      /**
       * Create a list of questions
       * use with "salto de pregunta"
       */
      generateQuestionsIndexList()
      {
        this.questionsIndex = [];
        for (let i = 0; i < this.values.questions.length; i++) {
          this.questionsIndex.push({text:`Pregunta ${i+1}`, value:i});
        }
      },


      /**
       * Show/hide fab button
       */
      onScroll (e) 
      {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset ||   e.target.scrollTop || 0
        this.fab = top > 100
      },

      /**
       * Scroll to bottom
       */
      tobottom () {
        var container = this.$refs.formContainer;
        console.log(container.scrollHeight);
        this.$vuetify.goTo(container.scrollHeight)
      },

      sendValues(questions = null){
      
        
        this.$emit('updateValues', { questions: this.values.questions});
        
      },

      
    }
}
</script>

<style lang="scss">
  .tab{
    &-header{
      font-size: 12px !important;
      color: var(--v-normalText-lighten4) !important;
     
    }

    &-active{
      color: var(--v-normalText-base) !important;
    }

    &-error{
      color: var(--v-warning-base) !important;
    }

    
  }
  .less-margin{
    margin-bottom: -20px;
  }


  .fab-questionary{
    right: 10% !important;
    bottom: 10% !important;
  }
</style>