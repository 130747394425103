
export default class Utils{

    months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    /**
     * Returns a 2 digit number always 
     * @param {string} num a number 
     */
    alwaysTwoDigits(num)
    {
        if(num.toString().length == 1)
            return `0${num}`;
        return num;
    }


    static getLastDayMonth(date)
    {
        // console.log(date)
        return new Intl.DateTimeFormat("es", { day: "2-digit" }).format(new Date(date.getFullYear(), date.getMonth() +1, 0));
        // return  new Date(date.getFullYear(), date.getMonth() +1, 0).getDate();
    }

    weeksYear(year)
    {
        let start = new Date(`${year}-01-01`);
        start.setHours(0,0,0,0);
        let end = new Date(`${year}-12-31`);
        end.setHours(0,0,0,0)

        let now = new Date();
        now.setHours(0,0,0,0)
        let broke = false;
        const weeks = [];
        let current = start;
        let valueStart = null;
        let valueEnd = null;
        let active = false;

        while (!broke) {
            
            //monday first day
            if(current.getDay() == 1){
                let weekText = `${this.alwaysTwoDigits(current.getDate())}/${this.months[current.getMonth()]}`;
                valueStart = current;
                current = current.addDays(6);
                weekText += ` - ${this.alwaysTwoDigits(current.getDate())}/${this.months[current.getMonth()]}`;
                valueEnd = current;
                // console.log(now, valueStart, now >= valueStart, now <= valueEnd, this.checkBetweenDates(now.toJSON().slice(0,10), valueStart.toJSON().slice(0,10), valueEnd.toJSON().slice(0,10)));
                if(now >= valueStart && now <= valueEnd)
                    active = true;

                valueStart = `${valueStart.getFullYear()}-${valueStart.getMonth()+1}-${valueStart.getDate()}`;
                valueEnd = `${valueEnd.getFullYear()}-${valueEnd.getMonth()+1}-${valueEnd.getDate()}`;
                weeks.push({text: weekText, value:`${valueStart}|${valueEnd}`, active:active});
                active = false;
            }
            current = current.addDays(1);

            if(current > end)
                broke = true;
        }
        return weeks;
    }

    checkBetweenDates(dateCheck, dateFrom, dateTo)
    {
        // var dateFrom = "02/05/2013";
        // var dateTo = "02/09/2013";
        // var dateCheck = "02/07/2013";

        var d1 = dateFrom.split("/");
        var d2 = dateTo.split("/");
        var c = dateCheck.split("/");

        var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
        var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
        var check = new Date(c[2], parseInt(c[1])-1, c[0]);

        console.log(check > from && check < to)
    }

    //format > dd/mm/yyyy
    static globalDateFormat(data) {
        const new_date = new Date(data);
        if (new_date instanceof Date && !isNaN(new_date)) {
          let dateArray = new Array();
          dateArray[0] = data.substring(0, 4);
          dateArray[1] = data.substring(5, 7);
          dateArray[2] = data.substring(8, 10);
  
          let date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  
          let ye = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
            date
          );
          let mo = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
            date
          );
          let da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(date);
          let newDate = `${da}/${mo.charAt(0).toUpperCase() + mo.slice(1)}/${ye}`;
          return newDate;
        }
        return new_date;
    }
    
    /**
     *  Number to currency format
     * @param {number} money 
     * @param {string} _currency 
     * @returns {string}
     */
    static currencyFormat(money, _currency = "MXN") {
        //console.log(money);
        // const currency = parseFloat(money).toFixed(2);
        const formatter = new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        });
        return formatter.format(money)+" " + _currency;
    }


    /**
     * Removes first word from a string
     * @param {string} phrase 
     * @returns {string} phrase without first word
     */
    static deleteFirstWord(phrase)
    {
        let spplited = phrase.split(" ");
        spplited.shift();
        return spplited.join(' ');
        
    }

    /**
     * Set page scroll to top.
     */
    static scrollToTop()
    {
        window.scrollTo(0,0);
    }

    /**
     * Capitalize first letter of every word
     * @param {string} phrase 
     * @returns {string} 
     */
    static capitalizePhrase(phrase)
    {
        return phrase.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    }

    /**
     * Returns phrase without a selected word.
     * @param {string} word word to delete
     * @param {string} phrase phrase base
     * @returns {string} new phrase
     */
    static deleteWord(word, phrase)
    {
        console.log(word, phrase);
        return phrase.replaceAll(word, '');
    }

    /**
     * Check if an object has properties
     * @param {object} obj object to check
     * @returns {boolean}
     */
    static objectIsEmpty(obj) {
        if(typeof obj == 'string')
            return true;
        return Object.keys(obj).length === 0;
    }

    /**
     * Returns date in selected time zone
     * @param {*} date date string | Date
     * @param {string} tzString timeZone
     * @returns {Date}
     */
    static convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date+' 00:00:00') : date).toLocaleString("en-US", {timeZone: tzString}));   
    }


    /**
     * Returns the object's value from a path
     * @param {object} obj 
     * @param {string} path 
     * @returns value
     */
    static getObjectValue(obj, path) {
        if (!path) return obj;
        const properties = path.split('.');
        try {
            return this.getObjectValue(obj[properties.shift()], properties.join('.'))
        } catch (error) {
            return null;
        }
        //return this.getObjectValue(obj[properties.shift()], properties.join('.'))
    }

    /**
     * Trim a string at a length and adds "..." at the end.
     * @param {string} input string
     * @param {number} length 
     * @returns {string}
     */
    static trimStringAt(input, length = 25)
    {
        return input.length > length ? input.substring(0, length - 3) + "...":input;
    }
}


Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}