<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-5">
                        <div class="font-weight-bold body-1">
                            Detalles Área - {{items[0].valor}}
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="5" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>

            </div>

            <v-row justify="center">
				<v-col cols="12" md="5" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
export default {
    components: {
        DetailsItem
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            items: [
                { concepto: 'Nombre', index: 'name', valor: null },
                { concepto: 'Empresa', index: 'company.name', valor: null},
                
            ],
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Areas",	
                click: () => {}
            },
         
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.areas.getEdit(this.id)
			.then((response) => {
                // this.items.forEach((y) => {
                //     y.valor = response.data[y.index]
                // });
                this.items= [
                    { concepto: 'Nombre', index: 'name', valor: response.data.name },
                    { concepto: 'Empresa', index: 'company.name', valor: response.data.company.name},
                    
                ];
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 