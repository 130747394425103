
export default class PageStateManager{

    //appTokenName = "rrhh_states";
    /*
        rrhh_states
        [
            {
                url: "",
                inputId:"",
                value:""

            },
            {
                。。。
            },
            {
                。。。
            }
        ]
        
    
    */

    /**
     * Returns sessionStorage token
     * @param {string} num a number 
     */
    static getState(url, inputId)
    {
        const states = JSON.parse(sessionStorage.getItem("rrhh_states"));

        if(states == undefined)
            return null;
    
        let state = states.find((item) => item.url == url && item.inputId == inputId);
        console.log(url, inputId, state)
        if(state == undefined)
            return null;

        return state.value;
    }

    /**
     * Save app token
     * @param {string} token 
     */
    static setState(url, inputId, value)
    {   
        // sessionStorage.setItem("rrhh_states", []);

        const states = JSON.parse(sessionStorage.getItem("rrhh_states"));

        //new
        if(states == undefined){
            let newItem = [];
                newItem.push(
                    { 
                        url,
                        inputId,
                        value
                    }
            );
            sessionStorage.setItem("rrhh_states", JSON.stringify(newItem));
            return;
        }

        //add
        let actualState = states.find((item) => item.url == url && item.inputId == inputId);
        //not found
        if(actualState == undefined){
            states.push(
                { 
                    url,
                    inputId,
                    value
                }
            );
        }else{
            actualState.value = value;
        }
        //save
        sessionStorage.setItem("rrhh_states", JSON.stringify(states));
    
    }

    /**
     * Delete app token
     */
    static removeState()
    {
        sessionStorage.removeItem("rrhh_states");
    }
    
}
